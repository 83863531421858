function isIPad() {
	return navigator.userAgent.match(/iPad/i) !== null;
}

function createFallbackImage(wrapper, config) {
	const img = document.createElement('img');
	img.src = config.fallback || config.poster;
	img.alt = 'Video';
	img.id = config.id;
	wrapper.appendChild(img);
}

function buildVideoElement(config) {
	const video = document.createElement('video');
	Object.assign(video, {
		muted: true,
		playsInline: true,
		autoplay: false,
		loop: config.loop,
		preload: config.preload,
		poster: config.poster,
		controls: false,
		id: config.id,
		style: { opacity: '0' },
	});
	video.classList.add('lazy');
	return video;
}

function addVideoSources(video, urls) {
	urls.split(',').forEach((url) => {
		const source = document.createElement('source');
		const trimmedUrl = url.trim();
		source.src = trimmedUrl;
		source.type = `video/${trimmedUrl.split('.').pop()}`;
		video.appendChild(source);
	});
}

function playVideo(video, wrapper) {
	video
		.play()
		.then(() => {
			video.style.opacity = '1';
			setTimeout(() => (wrapper.style.backgroundImage = 'none'), 500);
		})
		.catch((error) => {
			video.style.opacity = '1';
			console.error('Video playback error:', error);
		});
}

function createVideo(wrapper) {
	const config = {
		url: wrapper.dataset.video,
		id: wrapper.dataset.id || '',
		poster: wrapper.dataset.poster || '',
		preload: wrapper.dataset.preload || 'metadata',
		loop: wrapper.dataset.isloop !== 'false',
		forceImage: wrapper.dataset.forceimage === 'true',
		fallback: wrapper.dataset.fallback || '',
	};

	if (isIPad() && config.forceImage) {
		createFallbackImage(wrapper, config);
		// return;
	}

	const video = buildVideoElement(config);
	addVideoSources(video, config.url);
	wrapper.appendChild(video);
	playVideo(video, wrapper);
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.video').forEach(createVideo);
});
